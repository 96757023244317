const ProcessData = {

    paragraph : 'Expert par excellence dans son secteur, EcoTopo vous aide à parfaitement conduire votre projet en s\'appuyant à une offre complète de services de Haute Qualité!' ,
    titles : ['Phase1', 'Phase2', 'Phase3', 'Phase4'],
    List1 : ['Reception', 'Enregistrement', 'Preparation'],
    List2 :['Consultations', 'Reperage', 'Preparation'],
    List3 :['Levé', 'Calcul', 'Dessin', 'Contrôle'],
    List4 :['Dépôt', 'validation', 'Réception du récépissé']

};

export default  ProcessData;