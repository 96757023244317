import part1 from '../images/part-1.png';
import part2 from '../images/part-2.png';
import part3 from '../images/part-3.png';
import part4 from '../images/part-4.png';

export const PartnersData = [
    {key:1 ,image : part1},
    {key:2 , image : part2},
    {key:3 ,  image : part3},
    {key:4 , image : part4},
];